/**
 * @package     Joomla.Site
 * @subpackage  Templates.protostar
 * @copyright   Copyright (C) 2005 - 2016 Open Source Matters, Inc. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 * @since       3.2
 */

(function($)
{
	$(document).ready(function() {
		$('*[rel=tooltip]').tooltip()

		// Turn radios into btn-group
		$('.radio.btn-group label').addClass('btn');

		$('fieldset.btn-group').each(function() {
			// Handle disabled, prevent clicks on the container, and add disabled style to each button
			if ($(this).prop('disabled')) {
				$(this).css('pointer-events', 'none').off('click');
				$(this).find('.btn').addClass('disabled');
			}
		});

		$(".btn-group label:not(.active)").click(function()
		{
			var label = $(this);
			var input = $('#' + label.attr('for'));

			if (!input.prop('checked')) {
				label.closest('.btn-group').find("label").removeClass('active btn-success btn-danger btn-primary');
				if (input.val() == '') {
					label.addClass('active btn-primary');
				} else if (input.val() == 0) {
					label.addClass('active btn-danger');
				} else {
					label.addClass('active btn-success');
				}
				input.prop('checked', true);
			}
		});
		$(".btn-group input[checked=checked]").each(function()
		{
			if ($(this).val() == '') {
				$("label[for=" + $(this).attr('id') + "]").addClass('active btn-primary');
			} else if ($(this).val() == 0) {
				$("label[for=" + $(this).attr('id') + "]").addClass('active btn-danger');
			} else {
				$("label[for=" + $(this).attr('id') + "]").addClass('active btn-success');
			}
		});

		$('.coupon-button').click(function(e) {
			e.preventDefault();

			var coupon = $('.coupon_temp').val();

			if ($.trim(coupon) == "") {
				alert("U heeft geen coupon ingevuld.");
				return false;

			} else {
				$('.frm-coupon').find("[name='coupon']").val($.trim(coupon));

				$('.frm-coupon').submit();
			}

			return false;
		});

		$(".coupon_temp").keypress(function(event){
			if (event.keyCode == 13) {
				event.preventDefault();

				$(".coupon-button").click();

				return false;
			}
		})

	})
})(jQuery);
function validateMail(p1, p2) {
	if (p1.value != p2.value || p1.value == '' || p2.value == '') {
		p2.setCustomValidity('E-mail niet identiek');
	} else {
		p2.setCustomValidity('');
	}
}

// $(function () {
//
//
//
// 	// $('.input-daterange').datepicker({
// 	// 	format: 'dd-mm-yyyy',
// 	// 	autoclose: true,
// 	// 	calendarWeeks : true,
// 	// 	clearBtn: true,
// 	// 	disableTouchKeyboard: true
// 	// });
//
// 	$body.on('change click', function(e) {
// 		let $target = $(e.target);
//
// 		let $apply_date_today = $('.apply_date_today');
//
// 		if (e.type === "click") {
// 			if ($target.is($apply_date_today)) {
// 				let now = moment();
//
// 				$("#apply_date").datepicker("setDate", now._d);
// 				return false;
// 			}
// 		} else if (e.type === "change") {
//
// 		}
// 	});
// });
